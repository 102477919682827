import React from "react";
import RightImg1 from "./../../images/00-UC-Globe.png";
import RightImg2 from "./../../images/Yealink_SIP-T46G.png";
import LeftImg1 from "./../../images/People_DoctorPatientConsultation.png";

function Features() {
  return (
    <div>
      <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
        <div className="lg:pl-20 pl-8">
          <p className="font-normal text-xs mb-4">The DealerSys Touch </p>
          <h1 className="text-3xl font-medium mb-4">FIND YOUR SOLUTION</h1>
          <p className="text-base font-normal mb-16">
            Deep dive into your current infrastructure and spend to assess
            operational inefficiencies. <br />
            Provide a comparative report utilizing our latest and most
            comprehensive products and services.
            <br />
            Ensure sufficient bandwidth to run new potential applications.
            <br />
            Manage sales negotiations and provider relationships to guarantee
            the best pricing and support.
            <br />
          </p>
          <a
            href="/"
            className="underline uppercase font-medium tracking-widest text-sm"
          >
            SEE ALL VThe DealerSys Touch
          </a>
        </div>
        <div>
          <img src={RightImg1} alt="" />
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
        <div className="md:block hidden">
          <img src={LeftImg1} alt="" />
        </div>
        <div className="lg:pl-20 pl-8">
          <p className="font-normal text-xs mb-4">The DealerSys Touch</p>
          <h1 className="text-3xl font-medium mb-4">IMPLEMENTATION</h1>
          <p className="text-base font-normal mb-16">
            Work together to create a detailed transition plan to ensure a
            seamless implementation. <br />
            Manage the setup for each product and service, ensuring zero
            downtime. <br />
            Liaison for your team with the provider so everyone is on the same
            page and stays on it. <br />
            Hands-on integration support and training, leveraging best practices
            to assist clients with software setup and ensure complete
            compatibility.
          </p>
          <a
            href="/"
            className="underline uppercase font-medium tracking-widest text-sm"
          >
            VISIT OUR DEVELOPER CENTER
          </a>
        </div>
        <div className="md:hidden block">
          <img src={LeftImg1} alt="" />
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
        <div className="lg:pl-20 pl-8">
          <p className="font-normal text-xs mb-4">The DealerSys Touch</p>
          <h1 className="text-3xl font-medium mb-4">SUPPORT</h1>
          <p className="text-base font-normal mb-16">
            Continued direct access to executive level management for immediate
            resolution to any support issues saving you time and taking the
            headache out of your technology. <br />
          </p>
          <a
            href="/"
            className="underline uppercase font-medium tracking-widest text-sm"
          >
            SEE OUR SUPPORT PLANS
          </a>
        </div>
        <div>
          <img src={RightImg2} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Features;
