import React from "react";
import Werewe1 from "./../../images/gartner.png";
import Werewe2 from "./../../images/Frost_Sullivan.jpeg";
import Werewe3 from "./../../images/TMC_CRM_Excellence.png";
import Werewe4 from "./../../images/Cloud_Computing.png";

function Trusted() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-2">
        <div className="flex justify-center w-full">
          <h6 className="font-medium text-2xl mb-8">
            Trusted by trusted healthcare organizations
          </h6>
        </div>
        <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 place-items-center ">
          <div className="p-4">
            {" "}
            <img src={Werewe1} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe2} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe3} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe4} alt="" />
          </div>
          <div className="p-4">
            {" "}
            <img src={Werewe1} alt="" />
          </div>
          <div className="p-4">
            <img src={Werewe2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trusted;
