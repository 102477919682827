import React from "react";
import Headerp from "./../../images/shutterstock_1357087004.jpg";

function Header() {
  return (
    <div className="header-gradient">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div>
            <div className="mb-2">
              <span className="font-medium text-xs text-white"> CAREERS</span>
            </div>
            <h1 className=" font-medium text-white text-5xl mb-4">
              Financial services solutions
            </h1>
            <p className="text-base font-normal text-white mb-5">
              When it comes to financial services, privacy and compliance are
              top priorities. Unified communications, contact centers, and APIs
              work in harmony to offer a security-focused, real-time customer
              experience on any device, through any channel—voice, SMS,
              messaging, and video. Be the company your customers trust.
            </p>
            <div className="pt-16">
              <a
                href="/"
                className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
              >
                Contact an expert
              </a>
            </div>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full rounded-xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
